<template>
  <section>
    <div class="w-full bg-white p-4 rounded-md mt-4">
      <div class="w-full flex gap-2 font-bold text-lg items-center">
        <i class="pi pi-replay text-lg cursor-pointer" @click="$router.go(-1)"/>
        <p>Historial de pagos recibidos</p>
      </div>
      <div>
        <div class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-7 gap-2 my-2">
          <div class="gap-2" style="display: none">
            <label for="fechaInicio" class="w-full text-xs text-gray-600">Fecha inicio</label>
            <input id="fechaInicio" class="border rounded-md p-2 w-full text-sm" v-model="filtros.fechaInicio" type="date">
          </div>
          <div class="gap-2" style="display: none">
            <label for="fechaFin" class="w-full text-xs text-gray-600">Fecha fin</label>
            <input id="fechaFin" class="border rounded-md p-2 w-full text-sm" v-model="filtros.fechaFin" type="date">
          </div>
          <div class="gap-2">
            <label for="fechaFin" class="w-full text-xs text-gray-600">Estado</label>
            <Dropdown v-model="filtros.estado" showClear :options="estados" optionLabel="name" optionValue="id" placeholder="Seleccione" :inputStyle="{padding: '0.55rem'}" inputClass="text-sm" class="w-full border-gray-300 text-xs" />
          </div>
          <div class="gap-2" style="display: none">
            <label for="fechaFin" class="w-full text-xs text-gray-600">Valor máximo</label>
            <InputNumber class="w-full" inputClass="text-sm w-full" :inputStyle="{padding: '0.55rem', border: '1px solid rgba(226, 232, 240, var(--tw-border-opacity))'}" :max="999999" v-model="filtros.valor" />
          </div>
          <div class="gap-2">
            <label for="fechaFin" class="w-full text-xs text-gray-600">Ordenes</label>
            <InputText @keydown="validacionOrdenes($event)" @keyup.enter="guardarOrdenes" :onfocus="guardarOrdenes" v-model="ordenFiltrada" class="w-full text-sm border-gray-300" style="border: 1px solid rgba(226, 232, 240, var(--tw-border-opacity)); padding: 0.55rem"/>
            <div style="background-color: #f8f9fa;" class="flex flex-wrap overflow-auto max-h-20 gap-1 p-2" v-if="filtros.ordenes.length">
              <div v-for="(orden, i) in filtros.ordenes" :key="i">
                <div style="background-color: #EFF6FF;" class="p-1 gap-1 rounded-md flex text-blue-600">
                  <button @click="filtros.ordenes.splice(filtros.ordenes.findIndex(a => a === orden), 1)" class="text-blue-600 rounded-full"><i class="pi pi-times text-xs"></i></button>
                  <p>{{ orden }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full h-full flex gap-2 justify-end items-start" style="padding-top: 1.2rem;">
            <Button @click="obtenerHistorial" label="Buscar" class="w-full" style="height: fit-content; padding: 0.55rem;" icon="pi pi-search" />
            <Button @click="limpiarFiltros" label="Limpiar" severity="secondary" class="w-full" style="height: fit-content; padding: 0.55rem;" />
          </div>
        </div>
        <div class="w-full">
          <DataTable :value="listadoHistorial.rows" tableStyle="min-width: 50rem">
            <template #empty> No se encontraron registros. </template>
            <Column class="text-center text-sm" field="NumOv" header="#Ov"></Column>
            <Column class="text-center text-sm" field="CreatedAt" header="Fecha de registro">
              <template #body="{ data }">
                {{ dayjs(data.CreatedAt).locale('es').format('YYYY-MM-DD') }}
              </template>
            </Column>
            <Column field="DocNum" class="text-center text-sm" header="Numero de pago"></Column>
            <Column field="Valor" class="text-center text-sm" header="Valor"></Column>
            <Column field="estado" class="text-center text-sm" header="Estado">
              <template #body="{ data }">
                <Button v-if="data.Status === 3" label="Reenviar" severity="danger" class="p-1" size="small" icon="pi pi-replay"></Button>
                <div v-else class="flex gap-2 items-center" :class="data.Status === 1 ? 'text-blue-600' : 'text-green-600'">
                  <svg v-if="data.Status === 2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <i v-else class="pi pi-spin pi-spinner"></i>
                  <p class="font-bold">
                    {{ data.estado }}
                  </p>
                </div>
              </template>
            </Column>
          </DataTable>
          <Paginator v-model:first="offset"
            :rows="limit"
            :totalRecords="listadoHistorial.count"
            :rowsPerPageOptions="pageOptions"
            @page="onPage($event)"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { ref, onMounted, computed } from 'vue'
  import PagosRecibidosService from '../../../../services/pagos-recibidos.service'
  import dayjs from 'dayjs'
  import 'dayjs/locale/es'
  export default {
    name: 'historialPagosRecibidos',
    setup () {
      // Servicios
      const pageOptions = ref([10, 20, 30, 50, 100, 200, 500, 1000])
      const _PagosRecibidosService = ref(new PagosRecibidosService())
      const limit = ref(10)
      const offset = ref(0)
      const page = ref(0)
      const ordenFiltrada = ref()
      const listadoHistorial = ref({})
      const filtros = ref({
        // fechaInicio: dayjs().format('YYYY-MM-DD'),
        // fechaFin: dayjs().format('YYYY-MM-DD'),
        estado: null,
        ordenes: []
      })
      const estados = ref([
        {
          name: 'Procesando',
          id: 1
        },
        {
          name: 'Termino',
          id: 2
        },
        {
          name: 'Error',
          id: 3
        }
      ])
      const params = computed(() => {
        return {
          limit: limit.value,
          offset: offset.value,
          page: page.value,
          filter: filtros.value
        }
      })
      const limpiarFiltros = () => {
        filtros.value = {
          // fechaInicio: dayjs().format('YYYY-MM-DD'),
          // fechaFin: dayjs().format('YYYY-MM-DD'),
          estado: null,
          ordenes: []
        }
        pageOptions.value = [10, 20, 30, 50, 100, 200, 500, 1000]
        obtenerHistorial()
      }
      const obtenerHistorial = () => {
        _PagosRecibidosService.value.hitorial(params.value).then(({ data }) => {
          listadoHistorial.value = data
          listadoHistorial.value.rows.map(a => {
            if (a.Status === 0) a.estado = 'Pendiente'
            if (a.Status === 1) a.estado = 'Procesando'
            if (a.Status === 2) a.estado = 'Termino'
            if (a.Status === 3) a.estado = 'Error'
            return a
          })
        })
      }
      const guardarOrdenes = () => {
        if (!ordenFiltrada.value) return
        for (const orden of ordenFiltrada.value.split(' ')) {
          if (!filtros.value.ordenes.some(a => a === orden) && orden !== '') filtros.value.ordenes.push(orden)
        }
        ordenFiltrada.value = ''
        if (!pageOptions.value.includes(filtros.value.ordenes.length)) {
          pageOptions.value.push(filtros.value.ordenes.length)
          pageOptions.value.sort((a, b) => a - b)
          limit.value = filtros.value.ordenes.length
        } else {
          limit.value = filtros.value.ordenes.length
        }
      }
      const validacionOrdenes = (e) => {
        if (/[^0-9\s]/.test(e.key)) {
          if (e.key === 'Backspace' || e.key === 'v' || e.key === 'Control') {
            if (e.key === 'v' && !e.ctrlKey) {
              e.preventDefault()
            }
          } else {
            e.preventDefault()
          }
        }
      }
      const onPage = ({ first, page, pageCount, rows }) => {
        limit.value = rows
        obtenerHistorial()
      }
      onMounted(() => {
        obtenerHistorial()
      })
      return {
        filtros,
        limpiarFiltros,
        obtenerHistorial,
        listadoHistorial,
        guardarOrdenes,
        ordenFiltrada,
        estados,
        dayjs,
        offset,
        limit,
        page,
        onPage,
        validacionOrdenes,
        pageOptions
      }
    }
  }
</script>
